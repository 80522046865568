<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="goTo('/goods/label/add')">添加标签</a-button>
    </div>
    <a-table class="doc-table" :dataSource="dataSource" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'sort'">
          <a-input-number v-model:value="record.sort" :min="0" :max="100000" />
        </template>
        <template v-if="column.key === 'action'">
          <a-space>
            <a-button type="link" size="small" @click="goTo('/goods/label/edit')">编辑</a-button>
            <a-button type="link" size="small" danger>删除</a-button>
          </a-space>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const goTo = (path) => {
      router.push(path);
    };

    onMounted(() => {});

    return {
      goTo,
      dataSource: ref([
        {
          label_name: "折扣",
          image: "",
          sort: 0,
          is_show: 0,
        },
        {
          label_name: "新品",
          image: "",
          sort: 0,
          is_show: 0,
        },
        {
          label_name: "活动",
          image: "",
          sort: 0,
          is_show: 0,
        },
      ]),
      columns: [
        {
          title: "标签名称",
          dataIndex: "label_name",
          key: "label_name",
          width: "50%",
          rowDrag: true,
        },
        {
          title: "排序",
          dataIndex: "sort",
          key: "sort",
          width: "20%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "30%",
        },
      ],
    };
  },
});
</script>